import $ from 'jquery';
import { v1 as uuidv1 } from 'uuid';
import FormElement from './form-element'

/**
 * Forms related logic
 */
const Forms = (() => {

    /**
   * return form related logic impl.
   * 
   * TODO:
   * @urb max count of return items
   */
    const initReturnForm = () => {

        let $itemFieldgroupReplica, $form = $('form#form-return'), $addBtn, itemsCount = 1, itemsMaxCount = 10;

        if ($form.length) {

            /**
             * create and prepare new item fieldgroup 
             * @param {*} $replica 
             * @returns 
             */
            let createFieldGroup = ($replica) => {
                // clone replica
                let $copy = $replica.clone();

                // set id (return-item-[timestamp])
                $copy.attr('id', `return-item-${uuidv1()}`);

                // set visibility
                $copy.removeClass('hide')
                // enable inputs
                $('input', $copy).each((idx, input) => {
                    $(input).removeAttr('disabled');
                });

                return $copy;
            };

            /**
             * delete handler removes fieldGroup 
             * @param {*} $fieldGroup 
             */
            let removeFieldGroup = ($fieldGroup) => {
                $fieldGroup.remove();
                itemsCount--;

                // check for max items count
                if (itemsCount < itemsMaxCount) {
                    $addBtn.removeAttr('disabled')
                }
            };





            // get add item btn 
            $addBtn = $('#form-return-button-add', $form);

            $addBtn.on('click', function () {

                // check for max items count
                if (itemsCount < itemsMaxCount) {

                    // get item fieldgroup replica
                    if (!$itemFieldgroupReplica) {

                        $itemFieldgroupReplica = $('#return-item-fieldgroup-replica:hidden', $form);
                    }
                    // create  fieldgroup copy
                    let $itemFieldgroupCopy = createFieldGroup($itemFieldgroupReplica);

                    // bind handler to delete button
                    $('.button-delete-item', $itemFieldgroupCopy).on('click', removeFieldGroup.bind(null, $itemFieldgroupCopy));

                    // get last fieldgroup
                    let $lastFieldGroup = $(':not(#return-item-fieldgroup-replica).return-item-fieldgroup:last', $form);

                    // append copy after last fieldgroup
                    $itemFieldgroupCopy.insertAfter($lastFieldGroup);

                    itemsCount++;

                    // check max items
                    if (itemsCount == itemsMaxCount) {

                        // disable add button
                        $addBtn.attr('disabled', 'disabled')

                        // TODO: display some message?
                    }
                }

            });

            // initialize radios with dependent inputs
            FormElement.setupDependentElements($form);

            // alternative pick up address
            // enable/disable input fields
            $('details.pick-up-address', $form).on("toggle", (ev) => {
                if (ev.target.open) {
                    $('.form-item > input, .form-item > select', $(ev.target)).removeAttr('disabled')
                } else {
                    $('.form-item > input, .form-item > select', $(ev.target)).attr('disabled', 'disabled');
                }
            });

        };
    };

    const initCustomerSurveyForm = () => {

        const $form = $('form#form-customer-survey');

        if ($form.length) {
            FormElement.setupDependentElements($form);

            // TODO: @urb do we need that?
            // setup sticky header

            // get table
            //const $table = $('#edit-survey-questions-table');





        }



    };


    /**
       * ebl form 
       * 
       * TODO:
       * @urb 
       *  - max count of return items?
       *  - reindexing needed? placeholder text etc.
       */
    const initEBLForm = () => {

        let $item, $itemClone, $form = $('form#form-ebl'), $addBtn, $deleteBtn, itemsCount = 1, itemIdx = 1, itemsMaxCount = 10;


        if ($form.length) {

            /**
             * create and prepare new item 
             * @returns 
             */
            let createItem = () => {
                // clone replica with eventhandler bindings
                let $copy = $itemClone.clone(true);

                // set inputs ids/names
                $('input, select', $copy).each((idx, el) => {

                    let name = $(el).prop('name'), newName, newId;

                    if (name.includes('licenseno')) {

                        newName = `items[${itemIdx}][licenseno]`;
                    } else if (name.includes('gatewayid')) {
                        newName = `items[${itemIdx}][gatewayid]`;
                    } else if (name.includes('application')) {
                        newName = `items[${itemIdx}][application]`;
                        newId = `items-application-${itemIdx}`
                        $(el).prop('id', newId);

                    } else { }

                    // TODO reset values

                    $(el).prop('name', newName);

                });

                return $copy;
            };


            /**
             * add handler adds new item 
             * @param {*} $itemGroup element to append the new group
             */
            let addItem = ($itemGroup) => {

                // create item group copy
                let $itemNew = createItem();

                // append copy after current item group
                $itemNew.insertAfter($itemGroup);

                // init select element
                initSelect($('select', $itemNew));

                itemsCount++;
                itemIdx++;

                // check max items
                if (itemsCount == itemsMaxCount) {

                    // disable add buttons
                    $('.button-add-item', $form).attr('disabled', 'disabled');

                    // TODO: display some message?
                }

                // enable all delete buttons for 1+ items
                if (itemsCount > 1) {
                    $('.button-delete-item', $form).removeAttr('disabled');
                }

            };

            /**
             * delete handler removes fieldGroup 
             * @param {*} $itemGroup 
             */
            let removeItem = ($itemGroup) => {

                $('select', $itemGroup).select2('destroy');
                $itemGroup.remove();
                itemsCount--;

                // check for max items count
                if (itemsCount < itemsMaxCount) {
                    $('.button-add-item', $form).removeAttr('disabled');
                }
                // enable all delete buttons for 1+ items
                if (itemsCount == 1) {
                    $('.button-delete-item', $form).attr('disabled', 'disabled');
                }
            };

            /**
             * initialize select element with select2
             * @param {*} $select jquery select element
             * @param {*} options select2 options
             */
            let initSelect = ($select) => {

                $select.select2({
                    placeholder: $select.attr('placeholder')
                });
            };



            // get add item btn 
            $addBtn = $('#form-ebl-button-add', $form);
            $addBtn.on('click', function () {

                // get element to append the new group
                let $currentItemGroup = $(this).parents('.ebl-item-group')[0];


                // check for max items count
                if (itemsCount < itemsMaxCount) {
                    addItem($currentItemGroup);

                    

                }

            });

            $deleteBtn = $('#form-ebl-button-remove', $form);
            $deleteBtn.on('click', function () {

                let $currentItemGroup = $(this).parents('.ebl-item-group')[0];

                // bind handler to delete button
                removeItem($currentItemGroup);
            });


            // get item group element
            $item = $('.ebl-item-group', $form);

            // check and set itemsMaxCount
            itemsMaxCount &&= $item.data('items-max-count');

            // clone replica with eventhandler bindings
            $itemClone = $item.clone(true);

            // init first select
            let $selectEl = $('select', $item);
            initSelect($selectEl);

        };
    };


    return {
        init: () => {
            initReturnForm(),
                initCustomerSurveyForm(),
                initEBLForm();
        }
    }

})();

export default Forms;