import $ from 'jquery';


/**
 * Hierarchical select elements
*/
export default class HierarchicalSelects {


    constructor() {

        this.CONTAINER_SELECTOR = null;
        this.OPTIONS_DATA_SELECTOR = null;

        this.data = null;

        this.$container = null;
        this.$dataContainer = null;

        this.hideElements = null;
    }
    

    /**
     * 
     * @param {*} containerSelector selector for dom element containing select elements
     * @param {*} dataSelector selector for dom element containing the data
     * @param {Boolean} hideEmptyElements hide select elements that have no data, default is false
     */
    initialize(containerSelector, dataSelector, hideEmptyElements = false) {

        this.hideElements = hideEmptyElements;

        if (containerSelector) {

            this.CONTAINER_SELECTOR = containerSelector;

            this.$container = $(containerSelector);

            if (this.$container.length) {

                try {

                    // hide empty elements if set in option
                    if (this.hideElements) {
                        // get select elements except the first one
                        this.$container.find('.form-type-select:not(:first)').hide();

                    }



                    this.data = this.getData(dataSelector);

                    // create options
                    const selectData = this.createOptionsData(this.data['options']);


                    this.$container.each((idx, el) => {

                        let $groupContainer = $('select[data-hierarchy-level=1]', $(el));
                        // populate groups select with options
                        this.populateSelect($groupContainer, selectData)

                        // bind event handler
                        this.bindEventHandler($groupContainer);

                        this.performPreselect($groupContainer);
                    })

                } catch (error) {
                    console.error(error)
                }
            }
        } else {
            throw new Error('missing selector');
        }


    };

    /**
     * set selection for the select element defined by the data attribute data-selected-id
     * @param {*} $select 
     */
    performPreselect($select) {

        const selectedId = $select.data('selected-id');
        if (selectedId) {

            $select.val(selectedId).trigger({
                type: 'select2:select',
                params: {
                    data: {
                        id: selectedId
                    }
                }
            })

            $select.trigger('change'); // need to trigger change event to for display change in selection
        }

    }

    /**
     * 
     * resets select element and initializes it with data
     * 
     * @param {*} element select2 element
     * @param {*} data select2 formatted data [{id:1, text: 'option 1'},...]
     */
    populateSelect(element, data) {
        element.select2('destroy').empty()
            .select2({ data: data });
    };

    /**
     * bind/unbind event handler
     * @param {*} $select  select2 element
     */
    bindEventHandler($select) {

        // unbind events first
        $select.off("select2:select");
        $select.off("select2:unselect");
        $select.off("select2:clear");


        // bind events
        $select.on("select2:select", this.handleSelect.bind(this))
        $select.on("select2:clear", this.handleUnselect.bind(this))

    };

    /**
     * handler function for select event
     * @param {*} e 
     */
    handleSelect(e) {

        // get curren select element
        const $current = $(e.currentTarget);
        const $currentContainer = $current.closest(this.CONTAINER_SELECTOR);

        // reset dependent selects
        this.resetDependants($current);

        // current elements level
        let level = $current.data('hierarchy-level');

        const item = this.findNestedObjectByPropertyValue(this.data, 'id', e.params.data.id);

        // populate next select element
        const $nextSelect = $(`select[data-hierarchy-level=${++level}]`, $currentContainer);

        if ($nextSelect.length) {

            if (item['options']) {

                const nextData = this.createOptionsData(item['options']);

                this.populateSelect($nextSelect, nextData);

                // enable disabled element
                $nextSelect.prop('disabled', false);
                this.bindEventHandler($nextSelect);

                this.performPreselect($nextSelect);

                $nextSelect.parent('.form-type-select').show();

            } else {
                if (this.hideElements) {
                    $nextSelect.parent('.form-type-select').hide();
                }
            }
        }

    };

    /**
     * handler for unselect/clear event
     * @param {*} e 
     */
    handleUnselect(e) {
        const $current = $(e.currentTarget);
        this.resetDependants($current);
    };

    /**
     * resets current elements dependent selects
     * @param {*} $select current select element 
     */
    resetDependants($select) {
        // curren elements level
        let selLevel = parseInt($select.data('hierarchy-level'));
        const $currentContainer = $select.closest(this.CONTAINER_SELECTOR);

        // get select element candidates to be disabled.
        const $candidates = $(`select[data-hierarchy-level]`, $currentContainer).filter((idx, el) => {
            const elLevel = parseInt($(el).data('hierarchy-level'));
            return elLevel > selLevel;
        });

        // empty and disable selects with higher levels
        $candidates.val(null).trigger('change');
        $candidates.empty();
        $candidates.prop('disabled', 'true')
        if (this.hideElements) {
            $candidates.parent('.form-type-select').hide();
        }
    };


    /**
     * get json data set in the dom container
     * 
     * @param {*} dataContainerSelector 
     * @returns 
     */
    getData(dataContainerSelector) {

        this.OPTIONS_DATA_SELECTOR = dataContainerSelector;

        this.$dataContainer = $(dataContainerSelector);

        if (this.$dataContainer.length) {

            return JSON.parse(this.$dataContainer.text());
        } else {
            throw new Error('no data container defined for product options')
        }
    }


    /**
     * utility function to get object with by property key and value
     * @param {*} obj 
     * @param {*} targetKey 
     * @param {*} targetValue 
     * @returns 
     */
    findNestedObjectByPropertyValue = (obj, targetKey, targetValue) => {
        if (!obj || typeof obj !== 'object') {
            return undefined;
        }

        let queue = [obj];

        while (queue.length > 0) {
            let current = queue.shift();

            for (let key in current) {
                if (current.hasOwnProperty(key)) {
                    if (key === targetKey && current[key] == targetValue) {
                        return current;
                    }
                    if (typeof current[key] === 'object' && current[key] !== null) {
                        queue.push(current[key]);
                    }
                }
            }
        }

        return undefined;
    }


    /**
     * create options data for select2 element
     * 
     * if only single entry in option, set it selected
     * 
     * @param {*} options 
     * @returns 
     */
    createOptionsData = (options) => {

        const selectOptions = Object.entries(options).map((it) => {
            return {
                id: it[1].id,
                text: it[1].name,
            }
        });

        // add empty option at first place
        selectOptions.unshift({
            id: "",
            text: ""
        })

        return selectOptions;
    };

};

