import $ from 'jquery';
import HierarchicalSelects from './hierarchical-selects';


/**
 * Product options select elements
 * 
 * add class .product-options-container to the container holding the select elements
 * 
 * 
 * 
 * TODO:
 * - single selection value case handling?
 * 
 * 
 * 
 */
const ProductHS = (() => {
    const CONTAINER_SELECTOR = ".product-options-container",
    PRODUCT_OPTIONS_DATA_SELECTOR = "#product-options-data";

    const initialize = () => {

        const HS = new HierarchicalSelects();

        HS.initialize(CONTAINER_SELECTOR, PRODUCT_OPTIONS_DATA_SELECTOR)
    };

    return {
        init: initialize
    };

})();

export default ProductHS;