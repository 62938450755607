import $ from 'jquery';
import HierarchicalSelects from './hierarchical-selects';


/**
 * Handling of downloadcenters hierachical select elements
 * 
 */
const Downloadcenter = (() => {
    const CONTAINER_SELECTOR = "#downloadcenter-options-container",
    PRODUCT_OPTIONS_DATA_SELECTOR = "#downloadcenter-options-data",
    FORM_SELECTOR = "#downloadcenter-form";

    let HS;

    let $resetBtn;

    const initialize = () => {

        HS = new HierarchicalSelects();
        HS.initialize(CONTAINER_SELECTOR, PRODUCT_OPTIONS_DATA_SELECTOR, true);


        bindEvents();
    };

    const bindEvents = () => {

        $resetBtn = $(`${FORM_SELECTOR} .reset`);

        $resetBtn.length && $resetBtn.on('click', handleReset);

    }

    const handleReset = () => {
        $(`${FORM_SELECTOR} select`).val("").trigger('change');
        HS.initialize(CONTAINER_SELECTOR, PRODUCT_OPTIONS_DATA_SELECTOR, true);

    }

    return {
        init: initialize
    };

})();

export default Downloadcenter;